@use '../app/styles/common'

.wrapper
	--content-vertical-spacing: 40px

	position: relative
	padding:
		top: var(--content-vertical-spacing)
		bottom: var(--content-vertical-spacing)

	@media (min-width: 480px)
		--content-horizontal-spacing: 50px

.logo
	max-width: 150px

.content
	display: flex
	justify-content: center
	align-items: flex-end
	flex-wrap: wrap

	@media (min-width: 480px)
		justify-content: flex-start

.title
	position: relative
	font-size: 56px
	margin:
		left: 20px
		right: 20px
	color: #ffffff
	font-weight: 900

	@media (min-width: 768px)
		font-size: 66px

.background
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	z-index: -1
	background: common.$color-blue

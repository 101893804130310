.wrapper
	//

.tiles
	@media (min-width: 480px)
		display: grid
		grid-template-columns: 1fr 1fr
		grid-gap: var(--page-tile-gap)
		grid-auto-flow: dense

.tile
	width: 100%
	padding: 0
	border: none
	background-color: transparent

.item
	display: flex
	flex-direction: column

	@media (max-width: 480px)
		&:not(:first-child)
			margin-top: 40px

	&.view-wide
		@media (min-width: 480px)
			grid-column: span 2

.description
	$spacing: 20px
	margin-bottom: $spacing

	&.view-below
		order: 1
		margin: 0
		margin-top: $spacing

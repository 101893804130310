.wrapper
	margin-top: 20px
	position: relative
	z-index: -1

.backgrounds
	position: relative
	width: 100%
	padding-top: 100%

.item
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	visibility: hidden

	&.isActive
		visibility: inherit

.wrapper
	margin-top: 50px

	@media (min-width: 992px)
		padding:
			left: var(--page-horizontal-inner-spacing)
			right: var(--page-horizontal-inner-spacing)

.item
	&:not(:first-child)
		margin-top: 20px

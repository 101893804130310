@use '../app/styles/common'

.wrapper
	position: relative
	background: common.$color-blue
	z-index: 0

	&:hover, &:focus
		.hover
			visibility: visible
			opacity: 1

.hover
	display: flex
	justify-content: center
	align-items: center
	padding: 20px
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	background-color: rgba(darken(common.$color-blue, 35), .4)
	z-index: 1
	visibility: hidden
	opacity: 0
	transition: opacity .3s, visibility .3s

.title
	font-size: 24px
	color: #ffffff

	@media (min-width: 992px)
		font-size: 32px

.wrapper
	--container-width: var(--content-width-normal)
	--container-horizontal-spacing: var(--page-horizontal-spacing)

	max-width: calc(var(--container-width) + 2 * var(--container-horizontal-spacing))
	width: 100%
	margin-left: auto
	margin-right: auto
	padding-left: var(--container-horizontal-spacing)
	padding-right: var(--container-horizontal-spacing)

	&.view_size_wide
		max-width: none

		@media (min-width: 3840px)
			max-width: 3840px

	&.view_disableGutters
		padding: 0

@use '../app/styles/common'

.wrapper
	margin-top: -15px

	@media (min-width: 480px)
		margin-top: -20px

	@media (min-width: 560px)
		margin-top: -25px

	@media (min-width: 992px)
		margin-top: -30px
		padding:
			left: var(--page-horizontal-inner-spacing)
			right: var(--page-horizontal-inner-spacing)

.content

	@media (min-width: 560px)
		display: flex

.text
	line-height: 1.5
	margin:
		top: 20px
		bottom: auto

	@media (min-width: 560px)
		margin:
			top: auto
			left: -30px

.image
	$imageOffset: calc(var(--page-tile-gap) / 2) // to align the image like the the first (left) tile in DesignTiles
	background: common.$color-green

	@media (min-width: 560px)
		width: calc(50% - #{$imageOffset})
		flex-grow: 1
		flex-shrink: 0
		z-index: -1
		margin:
			top: auto
			bottom: auto
